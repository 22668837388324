export const socialLinks = {
  instagram:"https://www.instagram.com/0xalche/",
  youtube:"https://www.youtube.com/@justshiftjk",
  medium:"https://medium.com/@hotstuffjk0731",
  github: "https://github.com/justshiftjk",
  discord: "https://discord.com/users/1022009468835352647",
  linkedin: "https://www.linkedin.com/in/adam-glab-64a775272/",
  twitter: "https://x.com/0xAlche",
  telegram: "https://t.me/bettyjk_0915",
};
