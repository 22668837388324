import { PageType } from "../data/pages";

interface Props {
  page: PageType;
}

function Page({ page }: Props) {
  return (
    <>
      {/* <FollowOnGithub /> */}
      {page.module}
    </>
  );
}

export { Page };
